import "./ProjectSaya.css";
import {getLanguage} from "../../util/lang"

function ProjectSaya() {
    const lang = getLanguage();

    const copy = {
        header: "Project Saya",
        subtitle_1: "Kelas Koding dan Pemrograman",
        desc_1: `KELASKODING.ID adalah platform edukasi yang menyediakan kelas untuk mempelajari koding dan 
                pemrograman.`,
    }

    const copyEn = {
        header: "My Projects",
        subtitle_1: "Coding and Programming Classes",
        desc_1: `KELASKODING.ID is an educational platform that offers classes to learn coding and programming.`,
    }
  return (
    <section className="section project-saya" id="project-saya">
        <div className="container section-title" data-aos="fade-up">
            <h2>{lang == "id" ? copy.header : copyEn.header}</h2>
        </div>

        <div className="container">
            <div className="row gy-4 px-4">
                <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
                    <div className="project-saya-sub d-flex align-items-start">
                        <div className="pic"><img src="./assets/img/kelaskodingid.jpg" className="img-fluid" alt="" /></div>
                        <div className="sub-info">
                            <h4>KELASKODING.ID</h4>
                            <span>{lang == "id" ? copy.subtitle_1 : copyEn.subtitle_1}</span>
                            <p>
                                {lang == "id" ? copy.desc_1 : copyEn.desc_1}
                            </p>
                            <div className="link">
                                <a href="https://kelaskoding.id" target='_blank'><i className="bi bi-link-45deg"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  );
}

export default ProjectSaya;
