import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskTelkom() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc_1: `Saya bergabung dengan tim untuk mengembangkan proyek Standarisasi Golang, membantu membuat 
                template standar yang digunakan oleh tim lain untuk menghemat waktu saat membangun fitur 
                baru dari awal.`,
        desc_2: `Saya mengembangkan proyek Email Disbursement, yang mengotomatisasi pengiriman email terkait data 
                pencairan kepada administrator, menghemat waktu dalam mengumpulkan data dari berbagai sumber terdistribusi 
                dan meningkatkan efisiensi kerja.`,
        desc_3: `Saya juga mengembangkan proyek Modifikasi Data Pesanan di bagian backend, memungkinkan administrator 
                untuk memodifikasi data pesanan langsung tanpa perlu meminta bantuan dari pengembang, sehingga mempercepat 
                proses dan mengurangi beban kerja tim pengembangan.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc_1: `I joined the team to develop the Golang Standardization project, helping to create standard templates used 
                by other teams to save time when building new features from scratch.`,
        desc_2: `I developed the Email Disbursement project, which automates the sending of emails related to disbursement data 
                to administrators, saving time in gathering data from various distributed sources and improving work efficiency.`,
        desc_3: `I also developed the Order Data Modification project on the backend, allowing administrators 
                to modify order data directly without needing assistance from developers, thereby speeding up 
                the process and reducing the workload for the development team.`,
        closeButton: "Close",
    }
  return (
    <div className="modal modal-desk-telkom">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.title : copyEn.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.desc_1 : copyEn.desc_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.desc_2 : copyEn.desc_2} <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.desc_3 : copyEn.desc_3} <br />
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-telkom">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskTelkom;
