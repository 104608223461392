import './Brand.css';

function Brand() {
  return (
    <a className="navbar-brand js-scroll-trigger" href="#page-top">
        <span className="d-block d-lg-none d-md-none">Husni Firmansyah</span>
    </a>
  );
}

export default Brand;
