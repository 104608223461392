import React, {useEffect} from 'react';
import './Modal.css';
import ModalGajiTelkom from './ModalGajiTelkom.js';
import ModalSuratTelkom from './ModalSuratTelkom.js';
import ModalDeskTelkom from './ModalDeskTelkom.js';
import ModalSuratAlodokter from './ModalSuratAlodokter.js';
import ModalDeskAlodokter from './ModalDeskAlodokter.js';
import ModalSuratTokopedia from './ModalSuratTokopedia.js';
import ModalDeskTokopedia from './ModalDeskTokopedia.js';
import ModalSuratAegis from './ModalSuratAegis.js';
import ModalDeskAegis from './ModalDeskAegis.js';
import ModalSuratAce from './ModalSuratAce.js';
import ModalDeskAce from './ModalDeskAce.js';
import ModalDeskInovasi from './ModalDeskInovasi.js';
import ModalDeskMetanouva from './ModalDeskMetanouva.js';
import ModalDeskAplysit from './ModalDeskAplysit.js';

function Modal() {
    useEffect(() => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = './assets/js/body/pengalaman/modal/modal.js';
        document.body.appendChild(script);   
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);
  return (
    <>
        <ModalGajiTelkom />
        <ModalSuratTelkom />
        <ModalDeskTelkom />
        <ModalSuratAlodokter />
        <ModalDeskAlodokter />
        <ModalSuratTokopedia />
        <ModalDeskTokopedia />
        <ModalSuratAegis />
        <ModalDeskAegis />
        <ModalSuratAce />
        <ModalDeskAce />
        <ModalDeskInovasi />
        <ModalDeskMetanouva />
        <ModalDeskAplysit />
    </>
    
  );
}

export default Modal;
