import "./Pendidikan.css";
import {getLanguage} from "../../util/lang"

function Pendidikan() {
    const lang = getLanguage();

    const copy = {
        header: "Pendidikan",
        univ: "Universitas Pendidikan Indonesia, Bandung",
        study: "Sarjana Ilmu Komputer",
        desc: "Lulus pada tahun 2011.",
    }

    const copyEn = {
        header: "Education",
        univ: "Indonesia University of Education, Bandung",
        study: "Bachelor of Computer Science",
        desc: "Graduated in 2011.",
    }
  return (
    <section className="section pendidikan" id="pendidikan">
        <div className="container section-title" data-aos="fade-up">
            <h2>{lang == "id" ? copy.header : copyEn.header}</h2>
        </div>

        <div className="container">
            <div className="row gy-4 px-4">
                <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
                    <div className="pendidikan-sub d-flex align-items-start">
                        <div className="pic"><img src="./assets/img/upi.png" className="img-fluid" alt="" /></div>
                        <div className="sub-info">
                            <h4>{lang == "id" ? copy.univ : copyEn.univ}</h4>
                            <span>{lang == "id" ? copy.study : copyEn.study}</span>
                            <p>
                                {lang == "id" ? copy.desc : copyEn.desc}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  );
}

export default Pendidikan;
