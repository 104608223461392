import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskAlodokter() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc_1: `Saya memimpin tim untuk mengembangkan proyek Search Improvement, menambahkan fitur utama 
                    seperti menyimpan kata kunci pengguna, menampilkan produk yang direkomendasikan, dan memperbaiki 
                    kesalahan ketik, yang meningkatkan RPS sebesar 10%.`,
        desc_2: `Saya juga memimpin proyek Item Substitution, menawarkan produk alternatif saat produk yang 
                    diinginkan habis, meningkatkan CVR sebesar 15%.`,
        desc_3: `Saya mengembangkan proyek Admedika Integration di backend, memungkinkan pengguna untuk 
                    membeli produk yang ditanggung oleh asuransi Admedika, meningkatkan efisiensi transaksi 
                    dan kepuasan pengguna.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc_1: `I led the team in developing the Search Improvement project, adding key features such as saving user keywords, 
                displaying recommended products, and correcting typos, which improved the RPS by 10%.`,
        desc_2: `I also led the Item Substitution project, offering alternative products when the desired product is out of stock, 
                which increased the CVR by 15%.`,
        desc_3: `I developed the Admedika Integration project on the backend, enabling users to purchase products covered 
                by Admedika insurance, which improved transaction efficiency and user satisfaction.`,
        closeButton: "Close",
    }

  return (
    <div className="modal modal-desk-alodokter">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.title : copyEn.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.desc_1 : copyEn.desc_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.desc_2 : copyEn.desc_2} <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.desc_3 : copyEn.desc_3} <br />
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-alodokter">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskAlodokter;
