import React from 'react';

function ModalGajiTelkom() {
  return (
    <div className="modal modal-gaji-telkom">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    Gaji Per Bulan
                </h4>
            </header>
            <section className="modal-container-body rtf">
                <img src='./assets/img/slip_telkom.png' />
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-gaji-telkom">Tutup</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalGajiTelkom;
