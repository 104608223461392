import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskAplysit() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc: `Sebagai anggota tim, saya berkontribusi dalam pengembangan situs web yang memenuhi kebutuhan unik klien. 
                    Kami bekerja sama, menggabungkan keahlian untuk merancang dan membangun situs web yang 
                    mencerminkan identitas merek klien dan memenuhi persyaratan mereka.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc: `As a team member, I contributed to developing a website that met the client's unique needs. We collaborated, 
            combining our expertise to design and build a website that reflects the client's brand identity and meets 
            their requirements.`,
        closeButton: "Close",
    }

  return (
    <div className="modal modal-desk-aplysit">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.title : copyEn.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    {lang == "id" ? copy.desc : copyEn.desc}
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-aplysit">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskAplysit;
