import React, {useEffect} from 'react';
import './Menu.css';
import {getLanguage} from "../../util/lang";

function Menu() {
  useEffect(() => {
      const script = document.createElement('script');
      script.defer = true;
      script.src = './assets/js/nav/menu/menu.js';
      document.body.appendChild(script);   
      
      return () => {
          document.body.removeChild(script);
      };
  }, []);
  
  const lang = getLanguage();

  const copy = {
    home: "Beranda",
    experience: "Pengalaman",
    education: "Pendidikan",
    skill: "Keahlian",
    project: "Project Saya",
  }

  const copyEn = {
    home: "Home",
    experience: "Experience",
    education: "Education",
    skill: "Skills",
    project: "My Projects",
  }

  return (
    <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger active" href="#beranda">
                <i className='bi bi-house'></i>&nbsp;
                <span>{lang == "id" ? copy.home : copyEn.home}</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#pengalaman">
                <i className='bi bi-clock'></i>&nbsp;
                <span>{lang == "id" ? copy.experience : copyEn.experience}</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#pendidikan">
                <i className='bi bi-mortarboard'></i>&nbsp;
                <span>{lang == "id" ? copy.education : copyEn.education}</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#keahlian">
                <i className='bi bi-tools'></i>&nbsp;
                <span>{lang == "id" ? copy.skill : copyEn.skill}</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#project-saya">
                <i className='bi bi-briefcase'></i>&nbsp;
                <span>{lang == "id" ? copy.project : copyEn.project}</span>
              </a>
            </li>
        </ul>
    </div>
  );
}

export default Menu;
