import React from 'react';
import './Footer.css';

function Footer() {
  

  return (
    <footer id="footer" className="footer">
        <div className="container copyright text-center mt-4">
          <p>© <span>Copyright</span> <strong className="px-1 sitename">Husni Firmansyah</strong> <span>All Rights Reserved</span></p>
        </div>

      </footer>
  );
}

export default Footer;
