import React, {useEffect} from 'react';
import './App.css';
import Nav from './nav/Nav';
import Body from './body/Body';
import {getLanguage} from "./util/lang"

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = './assets/js/app.js';
    document.body.appendChild(script);   
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const lang = getLanguage();

  const copy = {
    link: "/",
    image: "assets/img/British-Flag.jpeg",
  }

  const copyEn = {
    link: "/?lang=id",
    image: "assets/img/Indonesia-Flag.png",
  }

  return (
    <div className="app">
      <Nav />
      <Body />

      {/* <a href={lang == "id" ? copy.link : copyEn.link} className="lang d-flex align-items-center justify-content-center">
        <img src={lang == "id" ? copy.image : copyEn.image}></img>
      </a> */}
      <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </div>
  );
}

export default App;
