import React, {useEffect} from 'react';
import "./Pengalaman.css";
import Modal from './modal/Modal';
import {getLanguage} from "../../util/lang";

function Pengalaman() {
    useEffect(() => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = './assets/js/body/pengalaman/pengalaman.js';
        document.body.appendChild(script);   
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const lang = getLanguage();

    const copy = {
        header: "Pengalaman",
    }

    const copyEn = {
        header: "Experience",
    }

  return (
    <section className="section pengalaman" id="pengalaman">
        <div className="container section-title" data-aos="fade-up">
            <h2>{lang == "id" ? copy.header : copyEn.header}</h2>
        </div>
        <div className="container">
            <div className="row gy-4 mb-4 px-4">
                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/telkom.jpg" alt="..." />
                        </div>
                        <h4>Telkom Indonesia, Jakarta</h4>
                        <p>Backend Developer (2023 - 2024)</p>
                        <div className="link">
                            {/* <a href="#" id='gaji-telkom'><i id='gaji-telkom' className="bi bi-cash-coin"></i></a>
                            <a href="#" id='surat-telkom'><i id='surat-telkom' className="bi bi-file-text"></i></a> */}
                            <a href="#" id='desk-telkom'><i id='desk-telkom' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/alodokter.jpg" alt="..." />
                        </div>
                        <h4>Alodokter, Jakarta</h4>
                        <p>Engineer Manager (2022 - 2023)</p>
                        <div className="link">
                            {/* <a href="#" id='surat-alodokter'><i id='surat-alodokter' className="bi bi-file-text"></i></a> */}
                            <a href="#" id='desk-alodokter'><i id='desk-alodokter' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/tokopedia.png" alt="..." />
                        </div>
                        <h4>Tokopedia, Jakarta</h4>
                        <p>Senior Software Engineer (2016 - 2022)</p>
                        <div className="link">
                            {/* <a href="#" id='surat-tokopedia'><i id='surat-tokopedia' className="bi bi-file-text"></i></a> */}
                            <a href="#" id='desk-tokopedia'><i id='desk-tokopedia' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/aegislabs.jpg" alt="..." />
                        </div>
                        <h4>Aegis Labs, Bandung</h4>
                        <p>Fullstack Software Engineer (2015 - 2016)</p>
                        <div className="link">
                            {/* <a href="#" id='surat-aegis'><i id='surat-aegis' className="bi bi-file-text"></i></a> */}
                            <a href="#" id='desk-aegis'><i id='desk-aegis' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/ace.jpg" alt="..." />
                        </div>
                        <h4>Adicipta Carsani Ekakarya, Jakarta</h4>
                        <p>Fullstack Software Engineer (2014 - 2015)</p>
                        <div className="link">
                            {/* <a href="#" id='surat-ace'><i id='surat-ace' className="bi bi-file-text"></i></a> */}
                            <a href="#" id='desk-ace'><i id='desk-ace' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/inovasi_tritek.jpeg" alt="..." />
                        </div>
                        <h4>Inovasi Tritek Informasi, Bandung</h4>
                        <p>Software Engineer (2013 - 2014)</p>
                        <div className="link">
                            <a href="#" id='desk-inovasi'><i id='desk-inovasi' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row gy-4 mb-4 px-4">

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/metanouva.jpg" alt="..." />
                        </div>
                        <h4>Metanouva Informatika, Bandung</h4>
                        <p>Software Engineer (2011 - 2012)</p>
                        <div className="link">
                            <a href="#" id='desk-metanouva'><i id='desk-metanouva' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="pengalaman-item position-relative">
                        <div className="icon">
                            <img className="img-fluid mx-auto mb-2" src="./assets/img/aplysit.jpeg" alt="..." />
                        </div>
                        <h4>AplysIT, Bandung</h4>
                        <p>Software Engineer (2010 - 2011)</p>
                        <div className="link">
                            <a href="#" id='desk-aplysit'><i id='desk-aplysit' className="bi bi-file-earmark-richtext"></i></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <Modal />
    </section>
  );
}

export default Pengalaman;
