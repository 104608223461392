import React, {useEffect} from 'react';
import './Nav.css';
import Brand from './brand/Brand';
import Menu from './menu/Menu';

function Nav() {
  useEffect(() => {
      const script = document.createElement('script');
      script.defer = true;
      script.src = './assets/js/nav/nav.js';
      document.body.appendChild(script);   
      
      return () => {
          document.body.removeChild(script);
      };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-expand-md navbar-dark fixed-top" id="sideNav">
        <Brand />
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          {/* <span className="navbar-toggler-icon"></span> */}
          <i className='bi bi-list'></i>
        </button>
        <Menu />
    </nav>
  );
}

export default Nav;
