import React, {useEffect} from 'react';
import "./Skill.css";
import {getLanguage} from "../../util/lang";

function Skill() {
    useEffect(() => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = './assets/js/body/skill/skill.js';
        document.body.appendChild(script);   
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const lang = getLanguage();

    const copy = {
        header: "Keahlian",
    }

    const copyEn = {
        header: "Skills",
    }

    const skills = [
        [
            { src: "assets/img/golang.png", name: "Golang" },
            { src: "assets/img/typescript.png", name: "Typescript" },
            { src: "assets/img/ruby_rails.png", name: "Ruby on Rails" },
            { src: "assets/img/php.png", name: "PHP" },
            { src: "assets/img/javascript.png", name: "Javascript" },
            { src: "assets/img/html5.png", name: "HTML" },
            { src: "assets/img/css3.png", name: "CSS" },
            { src: "assets/img/postgresql.png", name: "PostgreSQL" },
            { src: "assets/img/mongodb.png", name: "MongoDB" },
            { src: "assets/img/redis.png", name: "Redis" },
            { src: "assets/img/mysql.png", name: "MySQL" },
            { src: "assets/img/elasticsearch.png", name: "ElasticSearch" }
        ],
        [
            { src: "assets/img/nsq.png", name: "Message Queue" },
            { src: "assets/img/http.png", name: "HTTP" },
            { src: "assets/img/tcp.png", name: "TCP/IP" },
            { src: "assets/img/grpc.jpeg", name: "GRPC" },
            { src: "assets/img/graphql.png", name: "GraphQL"},
            { src: "assets/img/docker.png", name: "Docker" },
            { src: "assets/img/kubernetes.png", name: "Kubernetes" },
            { src: "assets/img/git.png", name: "Git" },
            { src: "assets/img/github.png", name: "Github" },
            { src: "assets/img/gitlab.png", name: "Gitlab" },
            { src: "assets/img/bitbucket.png", name: "Bitbucket" },
            { src: "assets/img/design_patterns.png", name: "Design Patterns" }
        ],
        [
            { src: "assets/img/refactoring.jpg", name: "Refactoring" },
            { src: "assets/img/solid.png", name: "SOLID Principles" },
            { src: "assets/img/clean_code.png", name: "Clean Code" },
            { src: "assets/img/clean_architecture.png", name: "Clean Architecture" },
            { src: "assets/img/microservices.png", name: "Microservices Architecture" }
        ]
    ];

  return (
    <section className="section keahlian" id="keahlian">
        <div className="container section-title" data-aos="fade-up">
            <h2>{lang == "id" ? copy.header : copyEn.header}</h2>
        </div>
        <div className="container">
            {skills.map((row, rowIndex) => (
                <div key={rowIndex} className="row gy-4 mb-4 px-4">
                    {row.map((skill, skillIndex) => (
                        <div key={skillIndex} className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <div className="skill-item position-relative">
                                <h6>{skill.name}</h6>
                                <div className="icon">
                                    <img className="img-fluid mx-auto mb-2" src={skill.src} alt="..." />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    </section>
  );
}

export default Skill;
