import './Body.css';
import Beranda from "./beranda/Beranda";
import Pengalaman from "./pengalaman/Pengalaman";
import Pendidikan from './pendidikan/Pendidikan';
import Skill from './skill/Skill';
import ProjectSaya from './project_saya/ProjectSaya';
import Footer from './footer/Footer';

function Body() {
  return (
    <div className='body'>
        <Beranda />
        <Pengalaman />
        <Pendidikan />
        <Skill />
        <ProjectSaya />
        <Footer />
    </div>
  );
}

export default Body;
