import React from 'react';

function ModalSuratTelkom() {
  return (
    <div className="modal modal-surat-telkom">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    Surat Keterangan Kerja
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <img src='./assets/img/paklaring_telkom.png' />
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-surat-telkom">Tutup</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalSuratTelkom;
