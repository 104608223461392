import React from 'react';
import {getLanguage} from "../../../util/lang";

function ModalDeskInovasi() {
    const lang = getLanguage();

    const copy = {
        title: "Deskripsi Pekerjaan",
        desc: `Sebagai bagian dari tim, saya berkontribusi dalam pengembangan situs web untuk rumah sakit dan 
                    klinik di Indonesia. Proyek ini bertujuan memenuhi kebutuhan fasilitas kesehatan dengan fitur 
                    yang memperlancar proses dan meningkatkan perawatan pasien. Peran saya meliputi coding, testing, 
                    dan debugging, memastikan situs web berfungsi dengan baik, mudah digunakan, dan andal. Bekerja sama 
                    dengan profesional kesehatan, kami terus menyempurnakan situs web agar sesuai dengan alur kerja 
                    dan preferensi mereka, menghasilkan solusi yang efisien dan meningkatkan pengalaman pasien.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        title: "Job Description",
        desc: `As part of the team, I contributed to the development of websites for hospitals and clinics in Indonesia. 
        The project aimed to meet the needs of healthcare facilities with features that streamline processes and 
        enhance patient care. My role included coding, testing, and debugging, ensuring that the website functioned well, 
        was user-friendly, and reliable. Collaborating with healthcare professionals, we continuously refined 
        the website to align with their workflows and preferences, resulting in an efficient solution that improved 
        the patient experience.`,
        closeButton: "Close",
    }

  return (
    <div className="modal modal-desk-inovasi">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.title : copyEn.title}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    {lang == "id" ? copy.desc : copyEn.desc}
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-desk-inovasi">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default ModalDeskInovasi;
